<template>
    <div style="padding: 0 20px 20px 20px;">
            <div  class="nameBox" style="padding-bottom:10px;">
                <span class="txt-title">关注企业</span>
            </div>
            <div v-if='total>0'>
                <div  class="t-btn">
                    <el-link :underline="false" class="btn-link"  @click.prevent="handleCommand()">取消关注</el-link>
                    <el-dropdown size="small" class="fr" @command='setSelectFun'>
                        <span class="el-dropdown-link">{{selectList.find(t=>t.isSel).name}}<i class="iconfont ec-icon-caret-bottom"></i></span>
                        <template #dropdown>
                            <el-dropdown-menu>
                            <el-dropdown-item v-for="item in selectList" :key="item.id" :command="item.id">{{item.name}}</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>
                <el-table  v-loading="loading" element-loading-text="正在加载中..."  :data="followList" stripe style="width:100%" 
                    @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="60" />
                    <el-table-column prop="entName" label="企业"  >
                        <template #default="scope">
                            <el-link :underline="false" :href="`/#/ep/epAnalysis?id=`+scope.row.companyId" target="_blank"  class="ep-link">{{scope.row.entName}}</el-link>
                            <span v-if='scope.row.state' class="tag_common" :class="{'tag_risk_bg':',2,3,4,5,'.indexOf(','+scope.row.state+',')>=0,'tag_normal_bg':',1,6,7,'.indexOf(','+scope.row.state+',')>=0}">{{stateList?.find(t=>t.id==scope.row.state).name}}</span>
                            <div style="line-height:30px;">
                                <span class="paddingright20">法人：<span class="txt-color">{{scope.row.legalPerson}}</span></span>
                                <span class="paddingright20">注册资本：{{scope.row.registeredcapital}}万{{capitalTypeList.find(t=>t.code==scope.row.capitaltype).name }} </span>
                                <span class="paddingright20">成立日期：{{parseTime( new Date(scope.row.startDate),'{y}-{m}-{d}')}}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="updateDate" label="关注日期" width="180" >
                        <template #default="scope">
                            <span >{{parseTime( new Date(scope.row.updateDate),'{y}-{m}-{d}')}}</span>
                        </template>
                        
                    </el-table-column>
                    <el-table-column  label="删除"  width="80">
                        <template #default="scope">
                            <el-link :underline="false" @click.prevent="handleCommand([scope.row.id])"><i class="iconfont ec-icon-delete"></i></el-link>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                class="page-cus"
                    @current-change="handlePageChangeFun"
                    :current-page="pageIndex"
                    :page-size='pageSize'
                    :hide-on-single-page="total<pageSize?true:false"
                    layout="prev, pager, next,total"
                    :total="total">
                </el-pagination>
                <!-- 删除 -->
            <el-dialog
                v-model="delDialogVisible"
                title="确认提示"
                width="30%">
                <span>{{'将取消关注'+selFollowList?.length+'个对象，删除后无法进行精准招商分析'}}</span>
                <template #footer>
                <span class="dialog-footer">
                    <el-button @click="delDialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="delFun">确认</el-button>
                </span>
                </template>
            </el-dialog>
            </div>
             <el-empty v-if='total<1' description="暂无关注企业哦~" />
               
        </div>
</template>
<script>
import {getEpState,getCapitalTypeList} from '@/http/epApi.js'
import {getUserFollowList,delUserFollow,addUserLog} from '@/http/basicsApi.js'
import {parseTime} from '@/utils/index.js'
import { ref } from 'vue'
import { ElMessage  } from 'element-plus'
import { useRoute } from 'vue-router'
export default {
    setup() {
        const route=useRoute() // useRoute();//当前路由
        let currentUser=ref(JSON.parse(localStorage.getItem('currentUser')));//当前用户信息
        let followList=ref([]);
        let total=ref(2);
        let pageIndex=ref(1);
        let pageSize=ref(20);
        let loading=ref(false);//正在加载。。。
        let stateList=ref([]);
        let capitalTypeList=ref([]);
        //企业状态
        const getEpStateFun=async()=>{
            
            let result=  await getEpState();
            //console.log(result);
            if(result.code==20000){
              stateList.value=result.data;
            }
        }
        //注册资本类型
        const getCapitalTypeListFun=async()=>{
            let res= await getCapitalTypeList();
            //console.log(res);
            if(res.code==20000 && res.data){
                capitalTypeList.value=res.data;
            }
        }
        
        let selectList=ref([{id:1, name:'默认排序',val:'id',isSel:true},{id:2, name:'关注日期从晚到早',val:'updateDate desc',isSel:false},{id:3,name:'关注日期从早到晚',val:'updateDate',isSel:false}]);//排序方式
        //获取用户关注的企业列表
        const getUserFollowListFun=async(type)=>{
            loading.value=true;
            let params={
                userId:currentUser.value?.userId,
                order:selectList.value.find(t=>t.isSel).val,
                pageSize:pageSize.value,
                pageIndex:pageIndex.value
                }
            let res=await getUserFollowList(params);
            //console.log(res);
            if(res.code==20000){
                total.value=res.data.totalCount;
                followList.value=res.data.dataList;
            }
            loading.value=false;
            let typeName='';
            //记录操作日志
            if(type>0){
                if(type==1){
                    typeName="加载";
                }
                else if(type==2){
                    typeName="分页查询";
                }
                else if(type==3){
                    typeName="排序";
                }
                //记录用户操作日志
                addUserLog(route.name,route.fullPath,typeName,JSON.stringify(params));
            }
        }
        //关注企业的复选框选中与取消选中
        let selFollowList=ref([]);//复选框选中的企业
        
        const handleSelectionChange = (val) => {
            selFollowList.value = val.map(t=>t.id);
        }
        let delDialogVisible=ref(false);
        //取消关注
        const handleCommand = (val) => {
            if(val && val.length>0){
                selFollowList.value=val;
            }
            if(selFollowList.value && selFollowList.value.length>0){
                delDialogVisible.value=true;
            }
            else{
                    ElMessage.warning({
                    message: '请选择要取消关注的企业' 
                }); 
            }
        }
        //取消关注
        const delFun=async()=>{
            //console.log(selFollowList.value);
            let params={ids:selFollowList.value.join(',')}
                let res=await delUserFollow(params);
                //console.log(res);
                if(res.code==20000){
                    selFollowList.value=[];
                    delDialogVisible.value=false;
                    //重新加载
                    pageIndex.value=1;
                    getUserFollowListFun(0);
                }
            //记录用户操作日志
            addUserLog(route.name,route.fullPath,'取消关注',JSON.stringify(params));
        }
        //选择排序
        const setSelectFun=(val)=>{
            //去掉选中
            selectList.value.filter(t=>{return t.isSel==true})[0].isSel=false;
            //重新选中
            selectList.value.filter(t=>{return t.id==val})[0].isSel=true;
            pageIndex.value=1;
            getUserFollowListFun(3);
        }
        //单击分页
        const handlePageChangeFun=(current)=>{
            pageIndex.value = current;
            getUserFollowListFun(2);
        }
        //加载关注的信息
        const init=async()=>{
            loading.value=true;
            await getEpStateFun();
            await getCapitalTypeListFun();
            await getUserFollowListFun(1);
        }
        //加载
        init();
        return {
            selectList,
            capitalTypeList,
            setSelectFun,
            stateList,
            followList,
            parseTime,
            total,
            pageIndex,
            pageSize,
            loading,
            handleSelectionChange,
            delDialogVisible,
            selFollowList,
            handleCommand,
            delFun,
            handlePageChangeFun
        }
    },
}
</script>
<style lang='scss' scoped>
@import '@/styles/variables.scss';
.el-dropdown-link{
    border: 1px solid $bordercolor;
    border-radius: 4px;
    padding: 3px 10px;
}
</style>